import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import ArrowRight from "../components/svg/arrowRight"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import {StaticImage} from "gatsby-plugin-image"
import Seo from "../components/seo"
import Cta from "../components/cta"

const AnuPage = () => (
    <Layout>
        <Seo 
            title="Web application development case study" 
            description="Case study of the web application development project completed by Launch Lab for Australia National University (ANU)"
        />

        <Hero
            h1="Scheduling web application development case study"  
            h2="Australia National University"
        />

        <section className="bg-black section-pad-sm">
            <div className="container" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/anu-main.png"
                        alt="Australia National University logo"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>The Department of Nuclear Physics, Research School of Physics and Engineering from Australia National University (ANU) approached Launch Lab to develop a cloud-based booking and scheduling application for the ANU's Heavy Ion Accelerator Facility.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/react-developers-sydney-canberra/" className="btn btn-lg btn-line">React development</Link>
                                    <Link to="/django-developer-sydney-canberra/" className="btn btn-lg btn-line">Python / Django development</Link>
                                    <span className="btn btn-lg btn-line">Docker</span>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>Catering to a number of different user types, the application enables facility management to manage facility users, the projects they work within, billing, and most importantly the facility schedule.</p>
                                    <p>Facility users can use the software to view various facility schedules and book experimental runs.</p>
                                    <p>The application also provides an analytics dashboard for Admin users where they can view facility user numbers, number of users from ANU vs other universities, the number of users from Australia vs rest of the world, the number of run hours used vs hours available to determine utilisation insights and insights on billing and cost recovery.</p>
                                    <p>The web application was developed using React.js and Python / Django. For delivery, the project was containerised using Docker so that ANU could host the application on premise.</p>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>The web appication is not publicly available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            text="Get an obligation free web application development or product design quote from an experienced local Australian development team."
        />
    </Layout>
)

export default AnuPage